import enLocale from "element-ui/lib/locale/lang/en";

// export default {
// module.exports = {
const en = {
  language: {
    language: "Language",
    changeLanguage: "Change language successfully",
    english: "English",
    chinese: "简体中文",
  },
  app: {
    // name: 'AIScene Cloud Platform',
    // title: 'AIScene Cloud Platform'
    name: "Smart Lighting Cloud Platform",
    title: "Smart Lighting Cloud Platform",
  },
  user: {
    login: "Log In",
    loginLoading: "Logging In...",
    logout: "Log Out",
    username: "Username",
    password: "Password",
    changePassword: "Change Password",
    accountCancellation: "Account Cancellation",
    accountCancellationConfirm:
      "Submit an application, delete all data, and permanently cancel the account",
    email: "Email",
    pleaseEnterEmail: "Please enter email",
    pleaseEnterCurrentPassword: "Please enter current password",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    pleaseEnterNewPassword: "Please enter new password",
    reEnterYourNewPassword: "Re-enter your new password",
    PleasereEnterYourNewPassword: "Please input the password again",
    twoPasswordNotMatch: "Two inputs don't match!",
    verificationCode: "Verification code",
    pleaseEnterVerificationCode: "Please enter verification code",
    sendVerificationCode: "Send verification code",
    secondsAgain: "s again",
    verificationCodeSendedSuccessfully: "Verification code sended successfully",
    changedSuccessfully: "Changed successfully",
    rememberMe: "Remember me",
  },
  menu: {
    workbench: "Workbench",
    feedback: "Feedback",
    login: "Log In",
    appDownload: "App Download",
    meshManagement: "Store Management",
    meshUserManagement: "Store User Management",
    assetsManagement: "Assets Management",
    deviceManagement: "Device Management",
    aiLight: "AI Light",
    luminaire: "Luminaire",
    mobileDevice: "Mobile Device",
    remoteControl: "Group Control",
    firmwareManagement: "Firmware Management",
    appsManagement: "App Management",
    statisticsManagement: "Commodity Popularity Data",
    customerStatistics: "Commodity Popularity",
    storePopularity: "Store Popularity",
    allStoresPopularity: "All Stores",
    popularityMap: "Popularity Map",
    storeHeatmap: "Store Heatmap",
    heatmap: "Heatmap",
    testEcharts: "Test Echarts",

    peopleCounting: "Customer Counting",
    storePeople: "Store People",
    currentHeatmap: "Current Heatmap",
    walkInCount: "Walk-in Count",
    userCenter: "User Center",
    roleManagement: "Role Management",
    deptManagement: "Dept Management",
    postManagement: "Post Management",
    staffManagement: "Staff Management",
    agentManagement: "Agent Management",
    agentPackage: "Agent Package",
    menuManagement: "Menu Management",
    platformSettings: "Platform Settings",
    parameterSettings: "Parameter Settings",
    dictManagement: "Dict Management",
    areaManagement: "Area Management",
    shipmentManagement: "Shipment Management",
    shipmentCalibration: "Shipment Calibration",
    userAdvancedSettings: "User Advanced Settings",
    kailinks: "Caimeta Cloud IoT",
    rkproductManagement: "Product Management",
    rkdeviceManagement: "Device Management",
  },
  components: {
    confirm: "Confirm",
    cancel: "Cancel",
    search: "Search",
    clear: "Clear",
    return: "Return",
    delete: "Delete",
    operation: "Operation",
    content: "Content",
    index: "Index",
    view: "View",
    email: "Email",
    add: "Add",
    yes: "Yes",
    no: "No",
    download: "Download",
    uploading: "Uploading",
    downloading: "Downloading",
    tips: "Tips",
    addedSuccessfully: "Added successfully",
    setSuccessfully: "Set successfully",
    modifiedSuccessfully: "Modified successfully",
    deleteItQuestion: "Are you sure to delete it?",
    verifyItQuestion: "Are you sure to set verified?",
    closeDialogQuestion: "Are you sure to close this dialog?",
    deletedSuccessfully: "Deleted successfully",
    verifiedSuccessfully: "Verified successfully",
    configuredSuccessfully: "Configured successfully",
    copiedSuccessfully: "Copied successfully",
    numberUnit: "",
    downloadedSuccessfully: "Downloaded successfully",
  },
  control: {
    pickADay: "Pick a day",
    today: "Today",
    yesterday: "Yesterday",
    aWeekAgo: "A week ago",
    startDate: "Start date",
    endDate: "End date",
    versionNumber: "Version",
    pleaseEnterVersion: "Please enter version",
    file: "File",
    dragFileHereOr: "Drag file here or",
    clickUpload: "click upload",
    updateContent: "Update content",
    inputUpdateContent: "Please enter update content",
    updateContentEnglish: "Update content in English",
    inputUpdateContentEnglish: "Please enter update content in English",
    firmwareType: "Fireware type",
    newFirmware: "New firmware",
    newFirmwareJson: "New firmware json",
    inputJson: "Please enter json",
    isForceUpdate: "Force updated",
    selectfirmwareType: "Select firmware type",
    pleaseUploadFile: "Please upload file",
    uploadedSuccessfully: "Uploaded successfully",
    uploadedFailure: "Uploaded failed",
    packageType: "Package type",
    packageTypeItem1: "whole package",
    packageTypeItem2: "subpackage",
    firmwareName: "Firmware name",
    firmwareProduct: "Product",
    firmwareModule: "Module",
    firmwareModuleItem1: "default",
    signatureAlgoithm: "Signature algoithm",
    signatureAlgoithmItem0: "No",
    signatureAlgoithmItem1: "MD5",
    signatureAlgoithmItem2: "SHA265",
    platformVerify: "Platform verify",
  },
  mesh: {
    meshName: "Store Name",
    email: "Email",
    userNickname: "User Nickname",
    userEmail: "User Email",
    adminEmail: "Admin Email",
    adminNickname: "Admin Nickname",
    memberNickname: "Member Nickname",
    memberEmail: "Member Email/Telephone",
    memberNumber: "Member Number",
    groupNumber: "Group Number",
    content: "Content",
    operation: "Operation",
    view: "View",
    dissolve: "Dissolve",
    nickname: "Nickname",
    account: "Account",
    registerDate: "Register Date",
    to: "to",
    delete: "Delete",
    admin: "Admin",
    member: "Member",
    groupMangement: "Group management",
    administratorTransfer: "Administrator transfer",
    administratorTransferTips: "Please select user",
    administratorTransferQuestion:
      "The original administrator will not have administrative access rights for the store",
    groupMangementView: "Group management - View",
    groupCode: "Group Code",
    groupName: "Group Name",
    dissolveItQuestion: "Are you sure to dissolve it?",
    dissolvedSuccessfully: "Dissolved successfully",
    pleaseDeleteTheMembersBeforeDissolution:
      "Please delete the members before dissolution!",
    meshNetworkCount: "Mesh Network Count",
    latestLoginIP: "Latest Login IP",
    relatedMeshNetworkCount: "Related Mesh Network Count",
    relatedMeshAdminCount: "Related Mesh Admin Count",
    theUserCanBeDeletedOnlyAfterAssociationInformationIsDeleted:
      "The user can be deleted only after association information is deleted.",
  },
  device: {
    deviceName: "Device Name",
    uuid: "UUID",
    deviceCode: "Device Code",
    meshId: "Mesh ID",
    country: "Country",
    createDate: "Create Date",
    meshName: "Mesh Name",
    address: "Address",

    allDevices: "All devices",
    selectGroups: "Select groups",
    allStores: "All stores",
    selectStores: "Select stores",
    colorPickerSelect: "Color Picker Select",
    quickSelect: "Quick Select",
    colorTemperature: "Color Temperature",
    brightness: "Brightness",
    done: "Done",
    pureRGB: "Pure RGB",
    upperLimitOfStoreSelection:
      "The max number of stores is {maxCount} for group control ",
  },
  firmware: {
    releaseVersion: "Release Version",
    factoryVersion: "Factory Version",
    developmentVersion: "Development Version",
    versionNumber: "Version",
    uploadDate: "Upload Date",
    updateContentChinese: "Content\n(Chinese)",
    updateContentEnglish: "Content\n(English)",
    firmwareType: "Firmware type",
    esp32Camera: "Meta1 ESP32",
    telinkNoCamera: "Meta1 Telink",
    meta2Camera: "Meta2 Pro",
    meta2NoCamera: "Meta2",
    meta2DualCamera: "Meta2 Pro DUAL",
    meta2C10: "Meta2 C10",
    dataRelay: "Data-relay",
    scenePannel: "Scene Pannel",
    switchPannel: "Switch Pannel",
    rkSceneController: "RK Scene Controller",
    rkPeopleController: "RK People Controller",
    handheldNoCamera: "Hand-held",
    handheldCamera: "Hand-held Pro",
    handheldDualCamera: "Hand-held Pro DUAL",
    metaUniNoCamera: "MetaUni",
    metaUniCamera: "MetaUni Pro",
    metaUniDualCamera: "MetaUni Pro DUAL",

    lunaNoCamera: "Luna",
    lunaCwNoCamera: "LunaUni",
    auraNoCamera: "Aura",
    lukaNoCamera: "Luka",
    auraCamera: "Aura Pro",
    lukaCamera: "Luka Pro",
    lukaUniCamera: "LukaUni Pro",
    musesCamera: "Muses Pro",
    musesDualCamera: "Muses Pro DUAL",
    buluceTwoTape: "Buluce Two Tape",
    kanoCamera: "Kano Pro",
    kanoDualCamera: "Kano Pro DUAL",
    accelerometerController: "Accelerometer Controller",
    haloCamera: "Halo Pro",
    zuesUniCamera: "ZuesUni Pro",
    zuesUniDualCamera: "ZuesUni Pro DUAL",
    lunaWW: "LunaWW",

    newType: "New Type",
    newFirmware: "New firmware",
    json: "JSON",
    isForceUpdate: "Force\nupdated",
    addReleaseFirmware: "Add release firmware",
    addFactoryFirmware: "Add factory firmware",
    addDevelopmentFirmware: "Add development firmware",
    verify: "Verify",
    firmwareVerifyItem0: "no need",
    firmwareVerifyItem1: "unverified",
    firmwareVerifyItem2: "verified",
    firmwareName: "Name",
    firmwareProduct: "Product",
    firmwareModule: "Module",
    firmwareVerify: "Status",
  },
  apps: {
    android: "Android",
    androidInternational: "Android International",
    ios: "IOS",
    iosInternational: "IOS International",
    versionNumber: "Version Number",
    uploadDate: "Upload Date",
    updateContentChinese: "Update Content(Chinese)",
    updateContentEnglish: "Update Content(English)",
    scope: "Scope",
    domestic: "Domestic",
    international: "International",
    zhimouji: "Zhimouji",
    caimeta: "Caimeta",
    sensetrack: "Sensetrack",
    proled: "Proled",
    avolux: "Avolux",
    hera: "Hera",
    hidealite: "Hidealite",
    selectScope: "Select scope",
    isForceUpdate: "Force updated",
    setDownloadVersion: "Set download version",
    currentDownloadVersion: "Current download version",
    addAndroidApp: "Add android app",
    addAndroidInternationalApp: "Add android international app",
    addIosApp: "Add iOS app",
    addIosInternationalApp: "Add iOS international app ",
  },
  statistics: {
    searchType: "Search Type",
    year: "Year",
    month: "Month",
    week: "Week",
    day: "Day",
    deviceName: "Device Name",
    LuminaireName: "Luminaire Name",
    storeName: "Store Name",
    date: "Date",
    changingTrendOfCustomerFlow: "Changing Trend of Customers Flow",
    RatioChartOfCustomerFlow: "Ratio Chart of Customers Flow",
    statisticalAverageOfStorePopularity:
      "Statistical Average of Store Popularity",
    rankOfCommodityPopularity: "Rank of Commodity Popularity",
    statisticalAllOfStorePopularity: "All Luminaires",
    selectOneOrMultipleStores: "Select one or multiple stores",
    selectOneStore: "Select one store",
    under5Seconds: "under 5s",
    above60Seconds: "above 60s",
    personTime: "Persons",
    totalNumber: "Total number",
    luminaire: "Luminaire",
    luminaires: "Luminaires",
    time: "Time",
    storeName: "Store Name",
    exportText: "Export",
    exportStoreDataQuestion: "Confirm to export all stores data?",
    exportLuminaireDataQuestion: "Confirm to export all luminaires data?",
    exportStoreDataFilename: "Statistical Average of Store Popularity.xlsx",
    exportLuminaireDataFilename: "Rank of Commodity Popularity.xlsx",
    mapConfig: "Map config",
    noDataAvailable: "No data available",
    light: "Light : ",
    peopleCount: "People Count : ",
    lightTheme: "Light Theme",
    changenMap: "Change map",
    automaticQuery: "Automatic query",
    realtime: "Recent",
  },
  people: {
    storeGate: "Store Gate",
    date: "Date",
    walkInCountAnalysis: "Walk-in Count Analysis",
    walkInCount: "Walk-in count",
    walkInCountTotal: "Walk-in count total",
    walkInCount2: "Walk-in Count",
    walkOutCount: "Walk-out count",
    passingFlowAnalysis: "Customer Counting Analysis",
    passingFlow: "Customer count",
    passingFlow2: "Customer Count",
    mom: "MoM",
    dod: "DoD",
    recent: "Recent",
    zoneInformation: "Zone Information",
    zoneDeviceName: "Zone device name",
    zoneRotate: "Zone rotate",
    clockwise0: "0°",
    clockwise90: "clockwise 90°",
    clockwise180: "clockwise 180°",
    clockwise270: "clockwise 270°",
    storePeopleCountMax: "Store people count max",
    storePeopleCountMin: "Store people count min",
    zonePeopleCountMax: "Zone people count max",
    zonePeopleCountMin: "Zone People count min",
  },
  feedback: {
    newRegistrant: "New Registrant",
    totalUser: "Total User",
    feedback: "Feedback",
    inputSearchEmail: "Please enter client's email",
  },
  third: {
    helpPage: "Caimeta",
  },
  tools: {
    tips: "Tips",
    confirm: "Confirm",
    cancel: "Cancel",
    confirmLong: " Confirm ",
    cancelLong: " Cancel ",
    yes: "yes",
    no: "no",
  },
  text: {
    message: "Smart Lighting Cloud Platform",
    language: "Language",
    noData: "",
  },
  ...enLocale, //or use Object.assign({message:'hello'},enLocale)
};

export default en;
